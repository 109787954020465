<!--
 * @Author: your name
 * @Date: 2021-07-23 15:01:36
 * @LastEditTime: 2021-08-25 14:23:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\login.vue
-->
<template>
  <div class="login"  :style="{ height: $store.state.clientHeight + 'px' }">
    <img class="logo" @click="$router.push({path:'/'})" src="@/assets/img/logo.png" alt="" />
    <div class="login-right" :is="component"></div>
    <div class="Copyright">Copyright @2016-2020 版权信息</div>
  </div>
</template>
<script>
import clogin from "./components/c_login.vue";
import cregister from "./components/c_register.vue";
import cforget from "./components/c_forget.vue";
export default {
  data() {
    return {
      component: "clogin",
    };
  },
  components: { clogin, cregister, cforget },
  mounted() {
    this.$bus.$on("switchComponent", (data) => {
      this.component = data;
    });
  },
};
</script>
<style lang="scss" scoped>
.login {
  background: url("../../assets/img/loginBG.png") no-repeat left center;
  position: relative;
  .logo {
    width: 232px;
    position: absolute;
    left: 286px;
    top: 41px;
    cursor: pointer;
  }
  .Copyright {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #979797;
    position: absolute;
    left: 50%;
    bottom: 34px;
    transform: translate(-50%, 0);
  }
}
</style>