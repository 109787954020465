<!--
 * @Author: your name
 * @Date: 2021-07-24 10:00:33
 * @LastEditTime: 2021-10-27 15:58:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\c_forget.vue
-->
<template>
  <div class="box">
    <h1>忘记密码</h1>
    <div class="from-input flex-vc flex-h flex-vc">
      <input type="text" placeholder="请输入账号" v-model="phone" />
    </div>
    <div class="from-input flex-vc flex-h flex-vc">
      <input
        style="width: 160px"
        type="text"
        placeholder="请输入验证码"
        v-model="code"
      />
      <div @click="getcode()" class="codeBox">{{ text }}</div>
    </div>
    <div class="from-input flex-vc flex-h flex-vc">
      <input type="password" placeholder="请设置新密码" v-model="newPwd" />
    </div>
    <div class="from-input flex-vc flex-h flex-vc">
      <input type="password" placeholder="再次输入新密码" v-model="oldPwd" />
    </div>
    <div class="from-input flex-vc flex-h flex-vc">
      <span style="font-size: 12px">{{ errText }}</span>
    </div>
    <div class="btn flex-h">
      <div class="btn1" @click="goLogin">确认修改</div>
      <div class="btn2" @click="$bus.$emit('switchComponent', 'clogin')">
        返 回
      </div>
    </div>
  </div>
</template>
<script>
import { forgetPwd,sendCode } from "@/api/index";
import md5 from "js-md5";
export default {
  data() {
    return {
      phone: "",
      code: "",
      newPwd: "",
      oldPwd: "",
      disable: false,
      text: "获取验证码",
      errText: "",
    };
  },
  methods: {
    goLogin() {
      if (this.check()) {
        forgetPwd({
          code: this.code,
          phone: this.phone,
          oldPwd: md5(this.oldPwd),
          newPwd: md5(this.newPwd),
        }).then((res) => {
          this.$store.commit("logout");
          this.$bus.$emit("switchComponent", "clogin");
        });
      }
    },
    check() {
      if (this.newPwd != this.oldPwd) {
        this.errText = "两次密码不一致";
        return false;
      }
      if (!(this.phone && this.code && this.newPwd && this.oldPwd)) {
        this.errText = "请输入完整信息";
        return false;
      }
      this.errText = "";
      return true;
    },
    timeOut() {
      let that = this;
      this.disable = true;
      var num = 60;
      var timer = setInterval(function () {
        num--;
        that.text = num + "秒";
        if (num <= 0) {
          that.disable = false;
          clearInterval(timer);
          that.text = "获取验证码";
        }
      }, 1000); //反复执行函数本身
    },
    getcode() {
      if (!this.phone) {
        this.errText = "请输入手机号";
        return false;
      }
      if (this.disable) {
        return false;
      }
      this.timeOut();
      sendCode({
        phone: this.phone,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  box-sizing: border-box;
  width: 471px;
  height: 506px;
  background: #ffffff;
  box-shadow: 0px 6px 9px 0px rgba(206, 206, 206, 0.5);
  padding: 0 60px;
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(0, -50%);
  h1 {
    text-align: center;
    font-size: 33px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #f53244;

    padding-top: 57px;
    margin-bottom: 43px;
  }
  .from-input {
    margin-bottom: 15px;
    span {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #f53244;
      line-height: 39px;
      margin-right: 3px;
    }
    input {
      width: 349px;
      height: 39px;
      border: 1px solid #d0d0d0;
      font-size: 20px;
      text-indent: 15px;
    }
  }
  .codeBox {
    width: 175px;
    height: 39px;
    background: #fff0f0;
    margin-left: 12px;
    cursor: pointer;
    font-size: 19px;
    font-family: MicrosoftYaHei;

    color: #f53244;
    text-align: center;
    line-height: 39px;
  }
  .btn {
    justify-content: space-between;
    .btn1 {
      cursor: pointer;
      width: 156px;
      height: 48px;
      background: #f53244;
      border-radius: 2px;
      border: 1px solid #ec2336;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 48px;
    }
    .btn2 {
      width: 156px;
      height: 48px;
      border-radius: 2px;
      border: 1px solid #ec2336;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f53244;
      line-height: 48px;
    }
  }
}
</style>