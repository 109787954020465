<!--
 * @Author: your name
 * @Date: 2021-07-23 15:28:14
 * @LastEditTime: 2021-08-20 14:27:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\c_login.vue
-->
<template>
  <div class="box">
    <h1>欢迎登录</h1>
    <input type="text" placeholder="请输入手机号" @keyup.enter="login" v-model="phone" />
    <input type="password" placeholder="请输入密码" @keyup.enter="login" v-model="password" />
    <div class="handle flex-h flex-vc">
      <span>{{ errText }}</span>
      <span @click="goForget">忘记密码？</span>
    </div>
    <div class="btn flex-h">
      <div class="btn1" @click="login">登 录</div>
      <div class="btn2" @click="goRegister">注 册</div>
    </div>
    <div class="radio">
      <img @click="radio = false" v-if="radio" src="@/assets/img/check1.png" />
      <img @click="radio = true" v-else src="@/assets/img/check2.png" />
      <span 
        >勾选即表示您已阅读并同意<a @click="$router.push({name:'protocol'})">《用户协议》</a>和<a @click="$router.push({name:'privacy'})">《隐私政策》</a></span
      >
    </div>
  </div>
</template>
<script>
import { login } from "@/api/index";
import {setToken,setUserInfo} from '@/utils/auth'
import md5 from "js-md5";
export default {
  data() {
    return {
      phone: "",
      password: "",
      radio: false,
      errText: "",
    };
  },
  methods: {
    check() {
      if (!(this.phone && this.password)) {
        this.errText = "请输入完整信息";
        return false;
      }
      if (!this.radio) {
        this.errText = "请阅读用户协议及隐私政策";
        return false;
      }
      this.errText = "";
      return true;
    },
    goForget() {
      this.$bus.$emit("switchComponent", "cforget");
    },
    goRegister() {
      this.$bus.$emit("switchComponent", "cregister");
    },
    login() {
      if (this.check()) {
        login({
          password: md5(this.password),
          phone: this.phone,
        }).then((res) => {
          let userInfo = res.data;
          setToken(userInfo.token)
          setUserInfo(userInfo)
          this.$store.commit("login", userInfo);
          this.$router.push({ name: "home" })
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
img{
  vertical-align: middle
}
.box {
  box-sizing: border-box;
  width: 471px;
  height: 521px;
  background: #ffffff;
  box-shadow: 0px 6px 9px 0px rgba(206, 206, 206, 0.5);
  padding: 0 60px;
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translate(0, -50%);
  h1 {
    text-align: center;
    font-size: 33px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #f53244;
    line-height: 44px;
    padding-top: 56px;
  }
  input {
    width: 100%;
    border-bottom: 2px solid #dddddd;
    font-size: 20px;
    &:nth-of-type(1) {
      margin-top: 72px;
      margin-bottom: 57px;
    }
  }
  .handle {
    justify-content: space-between;
    margin-top: 27px;
    span {
      &:nth-of-type(1) {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #f53244;
      }

      &:nth-of-type(2) {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #868686;
        cursor: pointer;
      }
    }
  }
  .btn {
    margin-top: 37px;
    justify-content: space-between;
    .btn1 {
      cursor: pointer;
      width: 156px;
      height: 48px;
      background: #f53244;
      border-radius: 2px;
      border: 1px solid #ec2336;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 48px;
    }
    .btn2 {
      width: 156px;
      height: 48px;
      border-radius: 2px;
      border: 1px solid #ec2336;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f53244;
      line-height: 48px;
    }
  }
  .radio {
    margin-top: 35px;
    img {
      cursor: pointer;
      margin-right: 5px;
    }
    span {
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #494545;
      a {
        color: #494545;
        cursor: pointer;
      }
    }
  }
}
</style>