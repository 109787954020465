<!--
 * @Author: your name
 * @Date: 2021-07-23 16:53:57
 * @LastEditTime: 2021-09-24 09:57:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\c_register.vue
-->
<template>
  <div class="box">
    <h1>注 册</h1>
    <div class="from-input flex-vc flex-h flex-vc">
      <label> <span>*</span>昵称</label>
      <input type="text" placeholder="请输入昵称" v-model="nickname" />
    </div>
    <div class="from-input flex-vc flex-h flex-vc">
      <label> <span>*</span>手机号</label>
      <input type="text" placeholder="请输入手机号" v-model="phone" />
    </div>
    <div class="from-input flex-vc flex-h flex-vc">
      <label> <span>*</span>验证码</label>
      <input
        style="width: 155px"
        type="text"
        placeholder="请输入验证码"
        v-model="code"
      />
      <div @click="getcode()" class="codeBox">{{ text }}</div>
    </div>
    <div class="from-input flex-vc flex-h flex-vc">
      <label> <span>*</span>设置密码</label>
      <input
        type="password"
        placeholder="请设置新密码"
        v-model="userPassword"
      />
    </div>
    <div class="from-input flex-vc flex-h flex-vc">
      <label> <span>*</span>确认密码</label>
      <input
        type="password"
        placeholder="再次输入新密码"
        v-model="confirmUserPassword"
      />
    </div>
    <div class="from-input flex-vc flex-h flex-vc">
      <label></label>
      <span style="font-size: 12px">{{ errText }}</span>
    </div>
    <div class="btn flex-h">
      <div class="btn1" @click="goAuth">完成注册</div>
      <div class="btn2" @click="goLogin">已有账号，马上登录</div>
    </div>
  </div>
</template>
<script>
import { sendCode, register } from "@/api/index";
import { setToken, setUserInfo } from "@/utils/auth";
import md5 from "js-md5";
export default {
  data() {
    return {
      phone: "",
      code: "",
      confirmUserPassword: "",
      userPassword: "",
      nickname: "",
      disable: false,
      text: "获取验证码",
      errText: "",
    };
  },
  methods: {
    goLogin() {
      this.$bus.$emit("switchComponent", "clogin");
    },
    goAuth() {
      if (this.check()) {
        register({
          code: this.code,
          confirmUserPassword: md5(this.confirmUserPassword),
          phone: this.phone,
          nickname: this.nickname,
          userPassword: md5(this.userPassword),
        }).then((res) => {
          let userInfo = res.data;
          setToken(userInfo.token);
          setUserInfo(userInfo);
          this.$store.commit("login", userInfo);
          this.$router.push({ name: "cert" });
        });
      }
    },
    check() {
      if (this.confirmUserPassword != this.userPassword) {
        this.errText = "两次密码不一致";
        return false;
      }
      if (
        !(
          this.phone &&
          this.code &&
          this.confirmUserPassword &&
          this.userPassword &&
          this.nickname
        )
      ) {
        this.errText = "请输入完整信息";
        return false;
      }
      this.errText = "";
      return true;
    },
    timeOut() {
      let that = this;
      this.disable = true;
      var num = 60;
      var timer = setInterval(function () {
        num--;
        that.text = num + "秒";
        if (num <= 0) {
          that.disable = false;
          clearInterval(timer);
          that.text = "获取验证码";
        }
      }, 1000); //反复执行函数本身
    },
    getcode() {
      if (!this.phone) {
        this.errText = "请输入手机号";
        return false;
      }
      if (this.disable) {
        return false;
      }

      sendCode({
        phone: this.phone,
      }).then((res) => {
        this.timeOut();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
input::placeholder {
  color: rgba(211, 211, 211, 1);
  font-size: 16px;
}
.box {
  box-sizing: border-box;
  width: 574px;
  height: 559px;
  background: #ffffff;
  box-shadow: 0px 6px 9px 0px rgba(206, 206, 206, 0.5);
  padding: 0 60px;
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translate(0, -50%);

  h1 {
    text-align: center;
    font-size: 33px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #f53244;
    padding-top: 24px;
    margin-bottom: 56px;
  }

  .from-input {
    margin-bottom: 15px;

    label {
      width: 108px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #545353;
      line-height: 39px;
    }

    span {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #f53244;
      line-height: 39px;
      margin-right: 3px;
    }

    input {
      width: 344px;
      height: 39px;
      border: 1px solid #d0d0d0;
      font-size: 20px;
      text-indent: 15px;
    }
  }

  .codeBox {
    width: 175px;
    height: 39px;
    background: #fff0f0;
    margin-left: 12px;
    cursor: pointer;
    font-size: 19px;
    font-family: MicrosoftYaHei;

    color: #f53244;
    text-align: center;
    line-height: 39px;
  }

  .btn {
    margin-top: 25px;
    justify-content: space-between;

    .btn1 {
      cursor: pointer;
      width: 182px;
      height: 48px;
      background: #f53244;
      border-radius: 2px;
      border: 1px solid #ec2336;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 48px;
    }

    .btn2 {
      width: 232px;
      height: 48px;
      border-radius: 2px;
      border: 1px solid #ec2336;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f53244;
      line-height: 48px;
    }
  }
}
</style>